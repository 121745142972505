import { createSelector } from "reselect";

const storeClosure = (store) => store;

export const getOnboardingState = createSelector(storeClosure, (store) => {
  return {
    welcome_video_watched: store.onboarding.welcome_video_watched,
    joined_facebook_group: store.onboarding.joined_facebook_group,
    questionnaire_completed: store.onboarding.questionnaire_completed,
    meeting_scheduled: store.onboarding.meeting_scheduled,
  };
});

export const getCurrentUser = createSelector(storeClosure, (store) => {
  return {
    ...store.authUser,
  };
});

export const getAdvisor = createSelector(storeClosure, (store) => {
  return {
    ...store.authUser.advisor,
  };
});

export const isOnboardingCompleted = createSelector(storeClosure, (store) => {
  return store.authUser.is_onboarding_completed;
});

export const getPromoteSettings = createSelector(storeClosure, (store) => {
  return {
    ...store.promote.promote_settings,
  };
});

export const getFunnelLinks = createSelector(storeClosure, (store) => {
  return {
    guest_user_funnel: store.promote.guest_user_funnel,
    master_class_funnel: store.promote.master_class_funnel,
    live_opportunity_call_funnel: store.promote.live_opportunity_call_funnel,
    exclusive_member: store.promote.exclusive_member,
  };
});

export const getActiveSubscription = createSelector(storeClosure, (store) => {
  return store.authUser.active_subscription.interval;
});

export const getPermissions = createSelector(storeClosure, (store) => {
  return store.permissions.userPermissions;
});

export const getUserProfile = createSelector(storeClosure, (store) => {
  return {
    avatar: store.authUser.avatar,
    name: store.authUser.name,
    instagram: store.authUser.instagram,
    email: store.authUser.email,
    phone: store.authUser.phone,
    phone_number: store.authUser.phone_number,
    country_code: store.authUser.country_code,
    country_iso_name: store.authUser.country_iso_name,
    paypal_account: store.authUser.paypal_account,
  };
});

export const getNotifications = createSelector(storeClosure, (store) => {
  return {
    ...store.authUser.notifications,
  };
});

export const getOnlineUsers = createSelector(storeClosure, (store) => {
  return store.onlineUsers.users;
});

export const getPostContent = createSelector(storeClosure, (store) => {
  return store.community.postCreated;
});

export const getNewCommunityContent = createSelector(storeClosure, (store) => {
  return store.communityCreation;
});
