import { combineReducers } from "@reduxjs/toolkit";

import onboarding from "./onboardingReducer";
import authUser from "./authUserReducer";
import promote from "./promoteReducers";
import permissions from "./permissionsReducer";
import onlineUsers from "./onlineUsersReducer";
import community from "./communityPostReducer";
import communityCreation from "./communityCreationReducer";

export default combineReducers({
  onboarding,
  authUser,
  promote,
  permissions,
  onlineUsers,
  community,
  communityCreation,
});
