import { UPDATE_NEW_COMMUNITY, GET_NEW_COMMUNITY } from "../types";

export const updateNewCommunity = (newCommunity) => {
  return {
    type: UPDATE_NEW_COMMUNITY,
    payload: {
      newCommunity,
    },
  };
};

export const getNewCommunity = () => {
  return {
    type: GET_NEW_COMMUNITY,
  };
};