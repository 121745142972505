import axios from "axios";

export const POST = "post";
export const COMMUNITY = "community";

export async function storeCommunityPost(data) {
  return await axios
    .post(`/${POST}/store`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function uploadPostMedia(data) {
  return await axios
    .post(`/${POST}/media`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function storePostBookmark(data) {
  return await axios
    .post(`/${POST}/bookmark`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function storePostComment(data) {
  return await axios
    .post(`/${POST}/comment`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function storePostReactions(data) {
  return await axios
    .post(`/${POST}/reaction`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function storePollVote(data) {
  return await axios
    .post(`/${POST}/poll/vote`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function createCommunity(data) {
  return await axios
    .post(`/${COMMUNITY}/store`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function updateCommunity(data) {
  return await axios
    .post(`/${COMMUNITY}/update`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function uploadCommunityIcon(data) {
  return await axios
    .post(`/${COMMUNITY}/media`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchAllPosts(postListType, postId, communityUuid) {
  return await axios
    .get(
      `/${POST}/index?post_list_type=${postListType}&post_id=${postId}&community_uuid=${communityUuid}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchSinglePosts(postUuid, community_uuid) {
  return await axios
    .get(`/${POST}/post?post_uuid=${postUuid}&community_uuid=${community_uuid}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchPostComments(postUuid, commentId = null) {
  return await axios
    .get(`/${POST}/comments?post_uuid=${postUuid}&comment_id=${commentId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchPostReactions(postUuid) {
  return await axios
    .get(`/${POST}/reactions?post_uuid=${postUuid}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchCommunities() {
  return await axios
    .get(`/${COMMUNITY}/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchCommunityUsers(userName) {
  return await axios
    .get(`/${COMMUNITY}/get-users-for-community?query=${userName}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function fetchSingleCommunity(uuid) {
  return await axios
    .get(`/${COMMUNITY}/edit?community_uuid=${uuid}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function deleteMyPost(data) {
  return await axios
    .post(`/${POST}/delete`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function deleteMyComment(data) {
  return await axios
    .post(`/${POST}/comment/delete`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}

export async function deleteCommunity(data) {
  return await axios
    .post(`/${COMMUNITY}/delete`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error.response.data;
    });
}
