import { cloneDeep } from "lodash";
import { mapObject } from "../../utils/generalHelper";
import { GET_NEW_COMMUNITY, UPDATE_NEW_COMMUNITY } from "../types";

const initialState = {
  id: null,
  uuid: null,
  name: null,
  image: null,
  type: null,
  created_at: null,
};

const communityCreation = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NEW_COMMUNITY:
      const stateClone = cloneDeep(state);
      mapObject(stateClone, action.payload.newCommunity);
      return {
        ...stateClone,
      };

    case GET_NEW_COMMUNITY:
      return state;

    default:
      return state;
  }
};

export default communityCreation;
